import BrandsSkeleton from '@/components/global/skeleton/BrandsSkeleton';
import MainBannersSkeleton from '@/components/global/skeleton/MainBannersSkeleton';
import StaticBannersSkeleton from '@/components/global/skeleton/StaticBannersSkeleton';
import MainBanners from '@/components/home/MainBanners';
import { getProductsByCategoryId } from '@/services/category';
import dynamic from 'next/dynamic';
import { Suspense } from 'react';

const OneStaticBanner = dynamic(
  () => import('@/components/home/StaticBanners'),
  {
    loading: () => <StaticBannersSkeleton numberOfBanners={1} />,
  }
);

const TwoStaticBanners = dynamic(
  () => import('@/components/home/StaticBanners'),
  {
    loading: () => <StaticBannersSkeleton numberOfBanners={2} />,
  }
);

const Brands = dynamic(() => import('@/components/home/Brands'), {
  loading: () => <BrandsSkeleton />,
});

const PromoProducts = dynamic(
  () => import('@/components/home/PromoProducts'),
  {}
);

const Products = dynamic(() => import('@/components/home/Products'), {});

const GridSwiper = dynamic(() => import('@/components/home/GridSwiper'), {});

const Categories = dynamic(() => import('@/components/home/Categories'), {});

export const getTabsData = async (handshake, tabs) => {
  if ('value' in tabs) {
    const firstTab = await getProductsByCategoryId(
      handshake,
      tabs?.value?.at(0)?.id,
      'sortBy=position&sortDir=ASC',
      '',
      12,
      0,
      false
    );
    const tabsProducts = tabs.value.map((tab, idx) =>
      idx === 0
        ? {
            ...tab,
            products: firstTab.data?.data?.products || [],
          }
        : { ...tab }
    );
    return { ...tabs, value: tabsProducts };
  }
  return tabs;
};

export const renderHomeSection = (sectionType, data, isMobile) => {
  switch (sectionType) {
    case 'first-section':
      return (
        <Suspense fallback={<MainBannersSkeleton data={data} />}>
          <MainBanners data={data} isMobile={isMobile} />
        </Suspense>
      );
    case 'flash-sale':
      return <PromoProducts data={data} isMobile={isMobile} />;
    case 'products-list':
      return <Products data={data} isMobile={isMobile} />;
    case 'products-grid':
      return <GridSwiper data={data} isMobile={isMobile} />;
    case 'categories':
      return <Categories data={data} isMobile={isMobile} />;
    case 'brands':
      return <Brands data={data} isMobile={isMobile} />;
    case 'two-main-banners':
      return <TwoStaticBanners data={data} isMobile={isMobile} />;
    case 'one-second-banners':
      return <OneStaticBanner data={data} isMobile={isMobile} />;
    default:
      return null;
  }
};
